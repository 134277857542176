@import "../../styles/_chunk";

.EmailSubscribeInsentiveBox {
  background-color: map-get($light-theme, card-background);
  color: $black;
  margin-bottom: 2rem;
  border-radius: 8px;

  @include theme(light) {
    background-color: map-get($dark-theme, card-background);
    color: $white;
  }

  .ReceiptEmailSignUp {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    justify-content: center;
    text-align: center;
    padding: 1rem;

    .email-subscribe {
      width: 100%;
    }
  }
}
