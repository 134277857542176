@import "../../styles/_chunk";

.receipt-splash-area {
  position: relative;

  @include media-breakpoint-up(md) {
    height: 240px;
  }

  @include media-breakpoint-up(lg) {
    height: 260px;
  }

  @include media-breakpoint-up(xl) {
    height: 280px;
  }

  .orders-splash {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    z-index: 1;
  }

  .splash-background-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      height: 240px;
    }

    @include media-breakpoint-up(lg) {
      height: 260px;
    }

    @include media-breakpoint-up(xl) {
      height: 280px;
    }

    img.hero-background {
      object-fit: cover;
      height: 100%;
    }
  }
}

.order-receipt {
  margin-top: 1.5rem;

  @include media-breakpoint-up(md) {
    margin-top: 0;
  }

  .receipt-content {
    margin-top: 0;

    h4 {
      font-weight: bold;
    }

    @include media-breakpoint-up(md) {
      margin-top: 3rem;
    }

    p {
      line-height: 1.6rem;

      a {
        @include brand-property(color, brand-core);

        text-decoration: underline;
      }
    }
  }

  .receipt-status-container {
    background-color: $card-background-dark;
    padding: 1rem;
    margin-bottom: 2rem;
    text-align: center;
    border-radius: $border-radius;

    @include theme(light) {
      background-color: map-get($light-theme, card-background);
      box-shadow: map-get($light-theme, card-box-shadow);
    }

    @include media-breakpoint-up(md) {
      padding: 2rem;
    }

    .status-title {
      margin-bottom: 1.5rem;

      h1 {
        @include brand-property(color, brand-core);

        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 0;
      }
    }

    .btn {
      text-transform: uppercase;
      font-weight: 700;
      margin-top: 0.5rem;
    }

    .spinner {
      margin: 0;
      display: flex;
      justify-content: center;
      width: 100%;
      height: 80px;
    }
  }

  .share-gift-container {
    background-color: $card-background-dark;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: $border-radius;

    @include theme(light) {
      background-color: map-get($light-theme, card-background);
      box-shadow: map-get($light-theme, card-box-shadow);
    }

    @include media-breakpoint-up(md) {
      padding: 2rem;
      margin-bottom: 2rem;
    }
  }

  .order-receipt-card-title {
    font-size: 1.2rem;
    padding: 1rem 1.4rem;
    color: $text-white;
    background-color: $grey-16;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  .order-receipt-card {
    background-color: $card-background-dark;
    margin-bottom: 2rem;
    border-radius: $border-radius;

    @include theme(light) {
      background-color: map-get($light-theme, card-background);
      box-shadow: map-get($light-theme, card-box-shadow);
    }

    .card-title {
      font-size: 1.25rem;
      margin-bottom: 0.875rem;
    }

    .faqs {
      padding: 1rem 1.4rem;

      p {
        margin-bottom: 0.4rem;
      }

      a {
        color: $text-white;

        @include theme(light) {
          color: map-get($light-theme, font-color);
        }

        &:hover {
          @include brand-property(color, brand-core);
        }
      }
    }

    .social-pages {
      padding: 1.5rem 0.5rem 1rem;
      border-radius: $border-radius;

      @include theme(light) {
        background-color: map-get($light-theme, card-background);
        box-shadow: map-get($light-theme, card-box-shadow);
      }
    }
  }

  .order-receipt-table {
    background-color: $card-background-dark;
    margin-bottom: 2rem;
    margin-top: 0;
    border-radius: $border-radius;

    @include media-breakpoint-up(md) {
      margin-bottom: 3rem;
      margin-top: 2.5rem;
    }

    .table-header {
      font-size: 1.2rem;
      padding: 1rem;
      color: $text-white;
      background-color: $grey-16;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include media-breakpoint-up(lg) {
        padding: 1rem 1.4rem;
      }
    }

    .order-table {
      margin-bottom: 0;
      background-color: $card-background-dark;
      border-radius: $border-radius;

      @include theme(light) {
        background-color: map-get($light-theme, card-background);
        color: map-get($light-theme, font-color);
      }

      th {
        padding: 0.5rem 1rem;

        @include media-breakpoint-up(lg) {
          padding: 0.5rem 1.4rem;
        }
      }

      tr {
        margin-bottom: 1rem;
      }

      tr:last-of-type {
        th {
          padding-bottom: 1rem;
        }
      }
    }
  }

  .email-subscribe-box {
    margin-bottom: 2rem;

    @include theme(light) {
      background-color: map-get($light-theme, card-background);
      box-shadow: map-get($light-theme, card-box-shadow);
    }

    .email-title-container {
      background-color: $grey-16;
      min-height: 100px;

      &::before {
        @include theme(light) {
          border-bottom: 8vw solid map-get($light-theme, card-background);
        }
      }
    }

    .small-text {
      @include theme(light) {
        color: $grey-4;
      }
    }
  }

  .assistant-banner {
    border-radius: $border-radius;

    @include theme(light) {
      background-color: map-get($light-theme, card-background);
      box-shadow: map-get($light-theme, card-box-shadow);
    }

    .header-text {
      background-color: $grey-16;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;

      &::before {
        @include theme(light) {
          border-bottom: 8vw solid map-get($light-theme, card-background);
        }
      }
    }
  }
}

.receipt-splash-heading-container {
  text-align: center;
  font-size: 1.6rem;

  @include media-breakpoint-up(lg) {
    font-size: 2rem;
  }

  .receipt-splash-heading {
    @include brand-property(background-color, brand-core);
    @include brand-property(color, font-color);

    padding: 0.8rem 2rem;
    font-weight: 700;
  }
}

.border-primary-2 {
  @include brand-property(border-color, brand-core, important);

  border: 3px solid $primary-core;
  border-radius: 0;
  text-align: center;

  h4 {
    @include brand-property(color, brand-core);
  }
}
